import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationSearchWithTypeAndOutputFilterComponent } from './destination-search-with-type-and-output-filter.component';
import {PpgSearchInputModule} from '../../../shared-components/ppg-search-input/ppg-search-input.module';



@NgModule({
    declarations: [
        DestinationSearchWithTypeAndOutputFilterComponent
    ],
    exports: [
        DestinationSearchWithTypeAndOutputFilterComponent
    ],
    imports: [
        CommonModule,
        PpgSearchInputModule
    ]
})
export class DestinationSearchWithTypeAndOutputFilterModule { }
