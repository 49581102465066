import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewSingleIntegrationModalComponent } from './view-single-integration-modal.component';



@NgModule({
	declarations: [
		ViewSingleIntegrationModalComponent
	],
	exports: [
		ViewSingleIntegrationModalComponent
	],
	imports: [
		CommonModule
	]
})
export class ViewSingleIntegrationModalModule { }
