import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDestinationModalComponent } from './edit-destination-modal.component';
import {FormsModule} from '@angular/forms';



@NgModule({
    declarations: [
        EditDestinationModalComponent
    ],
    exports: [
        EditDestinationModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class EditDestinationModalModule { }
