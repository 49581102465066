import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationsWithSearchAndFiltersComponent } from './destinations-with-search-and-filters.component';
import {DestinationSearchWithTypeAndOutputFilterModule} from '../destination-search-with-type-and-output-filter/destination-search-with-type-and-output-filter.module';
import {PaginationDataDetailsModule} from '../../../shared-components/pagination-data-details/pagination-data-details.module';
import {PpgPaginationModule} from '../../../shared-components/ppg-pagination/ppg-pagination.module';
import {PpgNoResultsModule} from '../../../shared-components/ppg-no-results/ppg-no-results.module';
import {SourcesWithSearchAndFiltersModule} from '../../source/sources-with-search-and-filters/sources-with-search-and-filters.module';
import {AddDestinationModalModule} from '../add-destination-modal/add-destination-modal.module';
import {PpgSlideModalWrapperModule} from '../../../shared-components/ppg-slide-modal-wrapper/ppg-slide-modal-wrapper.module';
import {DestinationDetailsModalModule} from '../destination-details-modal/destination-details-modal.module';
import {ModalModule} from '../../../shared-components/modal/modal.module';
import {DeleteConfirmationModalModule} from '../../../shared-components/delete-confirmation-modal/delete-confirmation-modal.module';
import {RouterModule} from '@angular/router';
import {EditDestinationModalModule} from '../edit-destination-modal/edit-destination-modal.module';



@NgModule({
    declarations: [
        DestinationsWithSearchAndFiltersComponent
    ],
    exports: [
        DestinationsWithSearchAndFiltersComponent
    ],
	imports: [
		CommonModule,
		DestinationSearchWithTypeAndOutputFilterModule,
		PaginationDataDetailsModule,
		PpgPaginationModule,
		PpgNoResultsModule,
		SourcesWithSearchAndFiltersModule,
		AddDestinationModalModule,
		PpgSlideModalWrapperModule,
		DestinationDetailsModalModule,
		ModalModule,
		DeleteConfirmationModalModule,
		RouterModule,
		EditDestinationModalModule
	]
})
export class DestinationsWithSearchAndFiltersModule { }
