import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddDestinationModalComponent } from './add-destination-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from '../../../shared-components/modal/modal.module';
import {DestinationDetailsModalModule} from '../destination-details-modal/destination-details-modal.module';



@NgModule({
    declarations: [
        AddDestinationModalComponent
    ],
    exports: [
        AddDestinationModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ModalModule,
        DestinationDetailsModalModule
    ]
})
export class AddDestinationModalModule { }
